<template>
  <div>
    <v-container class="faq-notice-container" style="display: block;">
        <br><br>
        <h1>License Agreement</h1>
        <small>Version 30.09.15. Last Updated 07 October 2015.</small>
        <br><br>
        <ul class="termsOfUseList" style="padding-left: 0px;">
          <li><strong>1.     LICENCE</strong>
              <ul>
                  <li>Subject to Your due and punctual performance and observance of the Contract, the Owner grants to You a non-exclusive licence to Use the App until the Contract is terminated in accordance with clause 9.
                  </li>
              </ul>
          </li>
          <li><strong>2.     MONTHLY SUBSCRIPTION FEE</strong>
              <ul>
                  <li>2.1       You must pay the Monthly Subscription Fee to the Owner in full and without deduction or set-off.</li>
                  <li>2.2       The Monthly Subscription Fee is non-refundable.</li>
                  <li>2.3       The Owner reserves the right to review the Monthly Subscription Fee from time to time provided that it will not increase the Monthly Subscription Fee during the first twelve (12) months of your access or more than once each twelve (12) months thereafter. Any reasonable increase in the Monthly Subscription Fee shall, once advised to you in writing, take effect as a variation of the Contract</li>
              </ul>
          </li>
          <li><strong>3.     PAYMENT</strong>
              <ul>
                  <li>3.1       Unless the Owner otherwise consents, all payments of the Monthly Subscription Fee must be made via the PayPal System.
                  </li>
                  <li>3.2       You acknowledge and agree Your use of the PayPal System is subject to PayPal’s T&amp;C.</li>
                  <li>3.3       The Owner reserves the right to pass through to You at cost any fees or charges imposed by PayPal in connection with any default by You in relation to payment, for example, when a withdrawal attempt fails because You provided PayPal with incorrect bank account or credit card details or a scheduled payment cannot be processed for any other reason due to Your error or oversight.</li>
              </ul>
          </li>
          <li><strong>4.     SUPPORT</strong>
              <ul>
                  <li>Support is available by telephone during the hours of 8.30am – 5.00pm Monday to Friday, and by email at any time. Support by other means or at other times (including training) is not included in the Monthly Subscription Fee but may be arranged by further negotiation with the Owner.</li>
              </ul>
          </li>
          <li><strong>5.     AUTHORISED USE</strong>
              <ul>
                  <li>5.1       You must at all times when Using the App fully comply with the Terms and Conditions of Use.</li>
                  <li>5.2       Only the Authorised Users are entitled to Use the App. You must take all reasonable steps and use Your best endeavours to ensure that:
                      <ul>
                          <li>(a)      the App is only Used by the Authorised Users;</li>
                          <li>(b)      all Authorised Users:
                              <ul>
                                  <li>(i)        have access to and agree to be bound by the Terms &amp; Conditions of Use;</li>
                                  <li>(ii)       at all times fully comply with the Terms and Conditions of Use; and</li>
                                  <li>(iii)      Use the App from or in a secure and virus free environment; and</li>
                              </ul>
                          </li>
                          <li>(c)      all information provided to You by the Owner to enable Authorised Users to Use the App (such as passwords, security certificates and intellectual property) is treated confidentially, used properly and not disclosed to any person (other than other Authorised Users) without the Owner’s prior consent.</li>
                      </ul>
                  </li>
                  <li>5.3       You must immediately notify the Owner if You become aware or suspect that:
                      <ul>
                          <li>(a)      any person other than an Authorised User has Used the App;</li>
                          <li>(b)      any person has Used the App from or in an environment that is not secure or virus free;</li>
                          <li>(c)      information provided to You by the Owner to enable Authorised Users to Use the App has not been treated confidentially or has been used improperly or disclosed to any person (other than other Authorised Users) without the Owner’s prior consent.</li>
                      </ul>
                  </li>
                  <li>
                      5.4       You must comply with any reasonable request by the Owner for substantiation of You and Your Authorised Users’ compliance with the requirements o subclauses 5.1 –5.3.
                      <span class="alert-warning">  The Terms &amp; Conditions of Use are available by contacting the Owner or by clicking <a href="terms-of-use.php">here</a>.</span>
                  </li>
              </ul>
          </li>
          <li><strong>6.     YOUR INFORMATION</strong>
              <ul>
                  <li>6.1       The Owner discloses that:
                      <ul>
                          <li>(a)      it will use Your Information to operate and provide the App; and</li>
                          <li>(b)      it may use technologies such as cookies to obtain information from You and your Authorised Users regarding Your or their Use of the App and/or the App’s performance.</li>
                      </ul>
                  </li>
                  <li>6.2       You authorise the Owner to use Your Information:
                      <ul>
                          <li>(a)      to comply with its obligations under the Contract;</li>
                          <li>(b)      to further develop the App and to develop other applications, products and/or services;</li>
                          <li>(c)      to otherwise conduct and develop the Owners’ business, including publication of Your plain text name and business images or logos for the purposes of promoting the Owner’s business; and</li>
                          <li>(d)      for any lawful purpose including for commercial gain provided that, except where identification is necessary or reasonable in the circumstances, all data which identifies You or an Authorised User as the source of the information is removed prior to disclosure of Your Information to any third party.</li>
                      </ul>
                  </li>
                  <li>6.3       You acknowledge and agree that:
                      <ul>
                          <li>(a)      it is Your responsibility to comply with all laws relating to retention of records pertaining to Your business; and</li>
                          <li>(b)      the Owner shall not be obliged to store Your Information for any period of time and may delete Your Information while the Contract is in force or after it is terminated either with or without prior notice.</li>
                      </ul>
                  </li>
                  <li>6.4       The Owner recommends that You either retain separate records of all of Your Information or regularly copy or export all of Your Information to alternate location(s).</li>
              </ul>
          </li>
          <li><strong>7.     CLOUD ENVIRONMENT</strong>
              <ul>
                  <li>7.1       The Owner discloses that the App will be hosted on and Used through a Cloud Environment.</li>
                  <li>7.2       You authorise the Owner to store Your Information in a Cloud Environment and acknowledge and agree that this:
                      <ul>
                          <li>(a)      involves risks against which the Owner may have little or no control, including the risk of theft and/or loss and/or disclosure of Your Information by or to third parties; and</li>
                          <li>(b)      may, and is likely to, involve the transfer of Your Information to a country outside of Australia.</li>
                      </ul>
                  </li>
                  <li>7.3       You indemnify the Owner and agree to keep the Owner indemnified from and against all Claims, Liabilities and Consequential Losses made, arising or suffered by or against You or an Authorised User in connection with the App being hosted on and Used through a Cloud Environment, including storage of Your Information that way.</li>
              </ul>
          </li>
          <li><strong>8.     SUSPENSIONS</strong>
              <p>The Owner reserves the right to suspend Your and Your Authorised Users’ Use of the App, including by removing all access rights, if:</p>
              <ul>
                  <li>(a)      You breach the Contract or an Authorised User breaches the Terms &amp; Conditions of Use and either:
                      <ul>
                          <li>(i)        You or the Authorised User (as the case may be) do not remedy the breach within seven (7) days after request from the Owner to do so; or</li>
                          <li>(ii)       the breach is incapable of remedy;</li>
                      </ul>
                  </li>
                  <li>(b)      You seriously or persistently breach the Contract or an Authorised User seriously or persistently breaches the Terms &amp; Conditions of Use. In this regard You agree, without limitation, that any breach by You of clause 5 is a serious breach of the Contract; or</li>
                  <li>(c)      You demonstrate intent to no longer be bound by the Contract or an Authorised User demonstrates intent to no longer be bound by the Terms &amp; Conditions of Use.</li>
              </ul>
          </li>
          <li>
          </li><li><strong>9.     TERMINATION</strong>
              <ul>
                  <li>9.1       You may terminate the Contract at any time via the ‘cancel’ function within the PayPal System.</li>
                  <li>9.2       The Owner reserves the right to terminate the Contract in any of the circumstances described in paragraph 8(a) through (c) immediately and without notice.</li>
                  <li>9.3       If the Contract is terminated You must:
                      <ul>
                          <li>(a)      return to the Owner or destroy any information that has come into Your possession or control in connection with Use of the App, including passwords and security certificates;</li>
                          <li>(b)      take all reasonable steps and use Your best endeavours to ensure that all Authorised Users do likewise; and</li>
                          <li>(c)      comply with any reasonable request by the Owner for substantiation of Your compliance with this clause.</li>
                      </ul>
                  </li>
              </ul>
          </li>
          <li><strong>10.    INDEMNITY</strong>
              <p>You indemnify the Owner and agree to keep the Owner indemnified from and against all Claims, Liabilities and Consequential Losses made, arising or suffered by or against the Owner in connection with suspension pursuant to clause 8 or termination pursuant to clause 9.</p>
          </li>
          <li><strong>11.    ENFORCEMENT COSTS</strong>
              <p>You agree to pay to the Owner on demand all Liabilities, expenses and other amounts incurred or paid by the Owner in respect of or in connection with:</p>
              <ul>
                  <li>(a)      any of the circumstances described in paragraphs 8(a) through (c);</li>
                  <li>(b)      the exercise or purported or attempted exercise of any of the rights of the Owner arising from or in connection with the Contract or for the preservation of those rights; and</li>
                  <li>(c)      the enforcement of any obligation You have to the Owner pursuant to or in connection with the Contract.</li>
              </ul>
          </li>
          <li><strong>12.    WARRANTIES &amp; LIABILITY</strong>
              <ul>
                  <li>12.1     All Liabilities, conditions, warranties and guarantees that may be excluded from the Contract are expressly negated.</li>
                  <li>12.2     Subject to clauses 12.3 and 12.4, in no event will the Owner or its suppliers or other affiliates be liable to You or an Authorised User for any Claim or Liability whatsoever, including any Consequential Loss, even if the Owner had been advised of the possibility or risk of such Claim or Liability at any material time.</li>
                  <li>12.3     To the extent that Liability for breach of a statutory condition, warranty or guarantee cannot be or is not excluded from the Contract but can be limited, Liability is so limited to payment of the cost of obtaining access to an equivalent application to the App for a period equivalent to the period for which you accessed the App prior to the date the Liability first arose.</li>
                  <li>12.4     Any Liability of the Owner to You or an Authorised User shall be reduced to the extent that You or an Authorised User caused or contributed to it.</li>
              </ul>
          </li>


          <li><strong>13.    DISPUTE RESOLUTION</strong>
              <ul>
                  <li>13.1     Subject to sub-clause 13.2, if a dispute arises out of or relates to the Contract (including any dispute as to breach or any Claim or Liability) You may not commence any proceedings relating to the dispute unless You have complied with the following paragraphs:
                      <ul>
                          <li>(a)      If You claim that a dispute (‘the Dispute’) has arisen under or in relation to the Contract You must give notice (‘the Notice’) to the Owner specifying the nature of the Dispute.</li>
                          <li>(b)      On receipt of the Notice by the Owner, You and the Owner (‘the Parties’) must endeavour in good faith to resolve the Dispute expeditiously using dispute resolution techniques such as negotiation, mediation, conciliation, arbitration and expert evaluation or determination or similar techniques agreed by them.</li>
                          <li>(c)      If the Parties do not resolve the Dispute within twenty-eight days (28) days of receipt of the Notice by the Owner (or as further period as agreed in writing by them) or do not agree within that time as to:
                              <ul>
                                  <li>(i)        the dispute resolution technique and procedures to be adopted to resolve the Dispute; and/or</li>
                                  <li>(ii)       the timetable for all steps in those procedures; and/or</li>
                                  <li>(iii)      the selection and compensation of the independent person required for the selected technique;</li>
                              </ul>
                          </li>
                      </ul>
                      <p>either Party may refer the Dispute to a mediator for mediation in accordance with and subject to the then current Mediation Rules of The Institute of Arbitrators and Mediators Australia.</p>
                  </li>
                  <li>13.2     Clause 13.1 does not apply where You seek urgent relief against the Owner or the Owner agrees in writing that it shall not apply.</li>
              </ul>
          </li>

          <li><strong>14.    NOTICES</strong>
              <ul>
                  <li>14.1     All notices to be given by a party pursuant to or in connection with this Agreement must be given in writing by any one of the following means:
                      <ul>
                          <li>(a)      by sending it to the other party by post or to its solicitor by document exchange in either of which cases it shall be deemed to be given and received two (2) Business Days after it has been sent;</li>
                          <li>(b)      by sending it to the party to be served or to its solicitor by delivery and read receipted email sent to:
                              <ul>
                                  <li>(i)        in the case of a notice addressed to You, the email address disclosed by You when You signed up for access to the App, or any other email address known or reasonably believed by the Owner to be used or accessed by You or Your solicitor; or</li>
                                  <li>(ii)       in the case of notice addressed to the Owner, to the following address: <a href="mailto:notices@recordtimeapp.com.au"><strong>notices@recordtimeapp.com.au</strong></a>; or</li>
                              </ul>
                          </li>
                          <li>(c)      by delivering it personally to the party to be served or to its solicitor, in which case it shall be deemed to be given and received on the day so delivered.</li>
                      </ul>
                  </li>
                  <li>14.2     A notice given, or a document signed or served, on behalf of any party by any director or company secretary or solicitor of that party shall be deemed to have been given, signed or served by that party personally.</li>
                  <li>14.3     In relation to service by email, a delivery or read receipt shall constitute sufficient and good proof of service for the purposes of this Agreement.</li>
              </ul>
          </li>

          <li><strong>15.    MISCELLANEOUS</strong>
              <ul>
                  <li>15.1     All rights conferred on the Owner in this Agreement shall operate without prejudice to any other rights to which the Owner may be entitled at law or in equity and may be exercised by the Owner without liability for any Claim or Liability arising or suffered by any person as a result, including as to any Consequential Loss.</li>
                  <li>15.2     This Agreement shall be governed by and construed in accordance with the laws from time to time applying in the Jurisdiction. The Owner and You submit to the exclusive jurisdiction of the courts of the Jurisdiction and, where available, the Commonwealth of Australia, and those that have jurisdiction to hear any appeals from them.</li>
                  <li>15.3     You must not attempt to offset any alleged entitlement against any monies owing to the Owner in connection with Use of the App and must not withhold payment by reason only that You claim the alleged entitlement.</li>
                  <li>15.4     For all purposes connected with the Contract, the Owner may provide or withhold its consent to the doing or execution of any act, matter or thing in its absolute discretion and on such terms as it shall deem fit, and a reference to the consent or approval of the Owner means the Owner’s express prior written consent or approval.</li>
                  <li>15.5     There is no implied waiver by the Owner in respect of any provision of the Contract and any delay or failure by the Owner to enforce any provision of the Contract will not be deemed to be a waiver. Any waiver granted by the Owner:
                      <ul>
                          <li>(a)      must be in writing;</li>
                          <li>(b)      shall be without prejudice to any other rights; and</li>
                          <li>(c)      will only be effective in relation to the particular obligation or breach in respect of which it is given and will not cover subsequent breaches of the same or a different kind.</li>
                      </ul>
                  </li>
                  <li>15.6     Subject to clause 2.3, no variation to, modification or consent to any departure form any provision of the Contract (including this clause) by the Owner shall in any event be of any force or effect unless the same shall be confirmed in writing and signed by the Owner and then such variation, modification or consent shall be effective only to the extent for which it may be made or given.</li>
                  <li>15.7     If any of the provisions of this Agreement shall be unlawful, void or unenforceable for any reason, the provision shall be deemed severable to the extent that it is lawful, void or unenforceable, but shall not affect the validity or enforceability of the remaining provisions.</li>
              </ul>
          </li>

          <li><strong>16.    DEFINITIONS</strong>
              <p>In this Agreement, unless the context otherwise requires or the contrary intention appears:</p>
          </li>

          <li><strong>17.    INTERPRETATION</strong>
              <p>In this Agreement, unless the context otherwise requires or the contrary intention appears:</p>
              <ul>
                  <li>(a)      the singular includes the plural and vice versa;</li>
                  <li>(b)      a reference to an individual or person includes a corporation, partnership, joint venture, association, authority, trust, state or government and vice versa;</li>
                  <li>(c)      where an expression is defined, another part of speech or grammatical form of that expression has a corresponding meaning;</li>
                  <li>(d)      “including” and similar expressions are not and must not be treated as words of limitation;</li>
                  <li>(e)      headings and tables of contents are inserted for convenience only and have no effect on interpretation;</li>
                  <li>(f)       any covenant or agreement by a party not to do an act or thing shall be deemed to include an obligation not to permit such act or thing to be done and to use its best endeavours to prevent such act or thing being done by another person;</li>
                  <li>(g)      a reference to a statute, regulation, proclamation, ordinance or by-law includes all statutes, regulations, proclamations, ordinances or by-laws amending, consolidating or replacing it, and a reference to a statute includes all regulations, proclamations, ordinances and by-laws issued under that statute; and</li>
                  <li>(h)      the term “notice” shall include notice, authorisation, request, nomination, notification and any other form of communication between the parties.</li>
              </ul>
          </li>
        </ul>
    </v-container>
  </div>
</template>

<style lang="scss">
.termsOfUseList{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 400;

    li {
        margin-bottom: 25px;
        margin-left: 0px;
    }
    strong{
      margin-bottom: 10px;
      display: block;
    }
    ul{
      list-style: none;
      margin-left: 0px;
      padding-left: 0px;

      li{
        margin-bottom: 10px;

        ul{
          margin-left: 20px;
        }
      }
    }
}
</style>

<script>
// @ is an alias to /src

export default {
  metaInfo: {
    title: 'Contact',
    titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: ''}
    ]
  },
  components: {
      
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
